import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { withRouter } from "react-router";
import { Table, TableBody, TableCell, TableHead, TableSortLabel, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress, Divider, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp } from '../../shared_elements';
import { TechSpecsNav } from '../../elements'
import { AddInteriorItem, AssetInfo } from '../components'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission, convertStringToNumber } from '../../utils_v2';
import { regexConstants } from "../../../constants/regEx";
import { downloadFileType } from "../../../utils";
import moment from "moment";
import { assetTypeValues, backendDateFormat, fieldDateFormat } from "../../../constants";
import { trackActivity } from "../../../utils/mixpanel";
import { titanAviInstance } from "../../../shared/components";
const avionicObj = {
    ata_code: '',
    item: '',
    quantity: null,
    manufacturer: '',
    model: '',
    part_number: '',
    item_description: '',
    avionics_family: ''
}
const AvionicHd = ({ sortInfo, createSortHandler }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'ata_code' ? true : false}
                        direction={sortInfo.sort === 'ata_code' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('ata_code')}
                    >
                        ATA Code
                    </TableSortLabel>
                </TableCell>
                {!titanAviInstance ?
                    <TableCell>
                        <TableSortLabel
                            active={sortInfo.sort === 'item' ? true : false}
                            direction={sortInfo.sort === 'item' ? sortInfo.sort_by : 'asc'}
                            onClick={() => createSortHandler('item')}
                        >
                            Item
                        </TableSortLabel>
                    </TableCell> : null}
                <TableCell style={{ width: '280px' }}>
                    <TableSortLabel
                        active={sortInfo.sort === 'item_description' ? true : false}
                        direction={sortInfo.sort === 'item_description' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('item_description')}
                    >
                        Description
                    </TableSortLabel>
                </TableCell>
                {titanAviInstance ?
                    <TableCell>
                        Item Status
                    </TableCell> : null}
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'manufacturer' ? true : false}
                        direction={sortInfo.sort === 'manufacturer' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('manufacturer')}
                    >
                        Manufacturer
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'model' ? true : false}
                        direction={sortInfo.sort === 'model' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('model')}
                    >
                        Model
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'part_number' ? true : false}
                        direction={sortInfo.sort === 'part_number' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('part_number')}
                    >
                        Part Number
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'quantity' ? true : false}
                        direction={sortInfo.sort === 'quantity' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('quantity')}
                    >
                        Quantity
                    </TableSortLabel>
                </TableCell>
                {titanAviInstance ?
                <TableCell>
                    Family
                </TableCell>:null}
                <TableCell align="right">Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const AvionicList = ({ item, onEdit, onDelete }) => {
    const [showMore, setShowMore] = useState({});
    const toggleDescription = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    return (
        <TableRow>
            <TableCell>
                <div style={{ width: '180px' }}>{item?.ata_code || '--'}</div>
            </TableCell>
            {!titanAviInstance ?
                <TableCell>
                    <div style={{ width: '280px' }}>{item?.item || '--'}</div>
                </TableCell> : null}
            <TableCell>
                {titanAviInstance ? <><div style={{ width: '280px' }}>{item?.item || '--'}</div>{<Divider style={{ margin: '5px 0px' }} />}</> : null}
                {item?.item_description && item?.item_description?.length > 30 ? (
                    <div style={{ width: '280px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.item_description}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.item_description.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div>{item.item_description || '--'}</div>
                }
            </TableCell>
            {titanAviInstance ?
                <TableCell>
                    <div style={{ width: '100px' }}>{item.item_status === true ? 'Yes' : 'No'}</div>
                </TableCell> : null}
            <TableCell>
                <div style={{ width: '180px' }}>{item?.manufacturer || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ width: '180px' }}>{item?.model || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ width: '180px' }}>{item?.part_number || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ width: '100px' }}>{item?.quantity || '--'}</div>
            </TableCell>
            {titanAviInstance?
            <TableCell>
                <div style={{ width: '100px' }}>{item?.avionics_family || '--'}</div>
            </TableCell>:null}
            <TableCell align="right">
                <ul className="list-inline" style={{ width: '80px', float: 'right' }}>
                    {
                        checkPermission('technical_specs', 'avionics', 'U') ?
                            <li className="list-inline-item hover-inline" onClick={onEdit}>
                                <Tooltip title='Edit' arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                    {
                        checkPermission('technical_specs', 'avionics', 'D') ?
                            <li className="list-inline-item hover-inline" onClick={onDelete}>
                                <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>
    )
}
const AddEditAvionic = ({ params, addEditAvionic, toggleModalFn, getResponseBack, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [avionic, setAvionic] = useState({ ...addEditAvionic?.data, item_status: addEditAvionic?.data?.item_status ?? true });
    const [error, setError] = useState({});
    const onFieldChange = (key, value) => {
        setAvionic({ ...avionic, [key]: value })
    }
    const onAddEditAvionic = () => {
        let validationInputs = {
            item: avionic?.item?.trim()?.length ? '' : 'Please enter Item',
            quantity: avionic?.quantity ? avionic?.quantity * 1 == 0 ? 'Please enter valid Quantity' : '' : 'Please enter Quantity'
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            const payload = Object.assign({}, convertStringToNumber(avionic, ['quantity']))
            setLoading(true);
            if (avionic.id) {
                globalPutService(`console/v2/${params.type}/${params.aircraft_slug}/avionics/${avionic.id}/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Item Edited', { page_title: 'Avionics', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Edit Avionics', event_desc: 'Edited Avionics data from Edit Avionics form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/avionics/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            trackActivity('Item Added', { page_title: 'Avionics', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Add Avionics', event_desc: 'Added Avionics data from Add Avionics form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    return (
        <Dialog
            open={addEditAvionic.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {avionic?.id ? 'Edit ' : 'Add '} Avionic
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <TextField
                            name='ata_code'
                            label='ATA Code'
                            value={avionic?.ata_code || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('ata_code', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            inputProps={{ maxLength: 25 }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            required
                            name='quantity'
                            label='Quantity'
                            value={avionic?.quantity || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('quantity', e.target.value) : e.preventDefault()}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 3 }}
                            error={error?.quantity}
                            helperText={error?.quantity || ''}
                            onFocus={() => setError({ ...error, 'quantity': '' })}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            required
                            name='item'
                            label='Item'
                            value={avionic?.item || ''}
                            fullWidth
                            margin="normal"
                            multiline
                            onChange={(e) => onFieldChange('item', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 250 }}
                            error={error?.item}
                            helperText={error?.item || ''}
                            onFocus={() => setError({ ...error, 'item': '' })}
                            variant='outlined'
                        />
                    </Grid>
                    {titanAviInstance ?
                        <Grid item md={12} style={{ padding: '0px 8px' }}>
                            <FormControl component="fieldset" margin="normal">
                                <FormLabel className="MuiInputLabel-shrink" component="label" >Item Status</FormLabel>
                                <RadioGroup row name="equipment_status" defaultValue={true} >
                                    <FormControlLabel value={true} control={<Radio color="primary" size="small" onChange={() => onFieldChange('item_status', true)} checked={avionic?.item_status === true} />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio color="primary" size="small" onChange={() => onFieldChange('item_status', false)} checked={avionic?.item_status === false} />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid> : null}
                    <Grid item md={4}>
                        <TextField
                            name='manufacturer'
                            label='Manufacturer'
                            value={avionic?.manufacturer || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('manufacturer', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='model'
                            label='Model'
                            value={avionic?.model || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('model', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='part_number'
                            label='Part Number'
                            value={avionic?.part_number || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('part_number', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    {titanAviInstance ?
                        <Grid item md={12}>
                            <TextField
                                name='avionics_family'
                                label='Family'
                                value={avionic?.avionics_family || ''}
                                fullWidth
                                margin="normal"
                                onChange={(e) => onFieldChange('avionics_family', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 250 }}
                                variant='outlined'
                            />
                        </Grid>
                        : null}
                    <Grid item md={12}>
                        <TextField
                            name='item_description'
                            label='Description'
                            value={avionic?.item_description || ''}
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            onChange={(e) => onFieldChange('item_description', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 250 }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditAvionic} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const Avionics = ({ params }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
    const [avionicsInfo, setAvionicsInfo] = useState({ list: [], pagination: null });
    const [addEditAvionic, setAddEditAvionic] = useState({ modal: false, data: null });
    const [deleteAvionic, setDeleteAvionic] = useState({ modal: false, data: null });
    const [assetInfoData, setAssetInfoData] = useState();
    const [assetSkeletonLoader, setAssetSkeletonLoader] = useState(false);
    useEffect(() => {
        getAvionics({}, 'skeletonLoader');
        getAssetInfo('assetSkeletonLoader');
    }, []);
    const getAssetInfo = (loaderType) => {
        loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/asset_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssetInfoData(response.data.data);
                }
                loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(false) : setLoading(false);
            })
    }
    const getAvionics = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/${params.type}/${params.aircraft_slug}/avionics/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAvionicsInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteAvionic = () => {
        setLoading(true);
        globalDeleteService(`console/v2/${params.type}/${params.aircraft_slug}/avionics/${deleteAvionic.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteAvionic({ modal: false, data: null });
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    getAvionics({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    const createSortHandler = (key) => {
        let sortQuery = { sort: key };
        if (sortInfo.sort === key) {
            sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
        } else {
            sortQuery = { ...sortQuery, sort_by: 'asc' }
        }
        setSortInfo(sortQuery)
        getAvionics({ ...filter, ...sortQuery, page: 1, per_page: avionicsInfo?.pagination?.per_page || 20 }, 'pageLoader');
    }
    const onExportAvionics = () => {
        setLoading(true);
        globalExportService(`console/v2/aircraft/${params.aircraft_slug}/avionics/?download=xlsx`)
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type].label}-${assetInfoData?.serial_number}_Avionics ${moment().format(fieldDateFormat)}`, 'xlsx')
                trackActivity('Export', { page_title: 'Avionics', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Avionics Imported', event_desc: ``, file_extension: 'xlsx' })
                setLoading(false)
            });
    }
    return (
        <section className="asset-tech-specs">
            <AssetInfo assetInfoData={assetInfoData} skeletonLoader={assetSkeletonLoader} getResponseBack={() => getAssetInfo()} />
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 162}px` }}>
                <TechSpecsNav />
                <div className="tech-specs-content">
                    {skeletonLoader ? <STableLoader count={8} /> :
                        <div style={{ padding: '10px' }}>
                            <AddInteriorItem
                                item={avionicsInfo}
                                addPermission={checkPermission('technical_specs', 'avionics', 'C')}
                                exportPermission={checkPermission('technical_specs', 'avionics', 'EXP')}
                                onAddItem={() => setAddEditAvionic({ modal: true, data: avionicObj })}
                                isImport
                                isExport
                                onExport={() => onExportAvionics()}
                                trackActivity={trackActivity('Import', { page_title: 'Avionics', event_type: 'Avioncs Imported', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_desc: ``, file_extension: 'xlsx' })}
                                getResponseBack={() => getAvionics({}, 'pageLoader')} url={`console/v2/aircraft/${params.aircraft_slug}/avionics/import/`} />
                            <Paper style={{ maxHeight: window.innerHeight - 290 + 'px', overflow: "scroll" }}>
                                <Table className='mui-table-format' stickyHeader>
                                    <AvionicHd createSortHandler={createSortHandler} sortInfo={sortInfo} />
                                    <TableBody>
                                        {avionicsInfo.list.map((item, index) =>
                                            <AvionicList
                                                key={index}
                                                item={item}
                                                onEdit={() => setAddEditAvionic({ modal: true, data: item })}
                                                onDelete={() => setDeleteAvionic({ modal: true, data: item })}
                                            />
                                        )}
                                    </TableBody>
                                </Table>
                            </Paper>
                            {avionicsInfo?.pagination ?
                                <Paper>
                                    <Pagination
                                        pagination={avionicsInfo.pagination}
                                        onChangePage={(event, newPage) => getAvionics({ ...filter, page: newPage + 1, per_page: avionicsInfo.pagination.per_page }, 'pageLoader')}
                                        onChangeRowsPerPage={(event) => getAvionics({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                                    />
                                </Paper>
                                : null
                            }
                            <Paper>
                                {!avionicsInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                            </Paper>
                        </div>
                    }
                </div>
                {addEditAvionic.modal ?
                    <AddEditAvionic
                        params={params}
                        assetInfoData={assetInfoData}
                        addEditAvionic={addEditAvionic}
                        toggleModalFn={() => setAddEditAvionic({ modal: false, data: null })}
                        getResponseBack={() => getAvionics({}, 'pageLoader')}
                    /> : null
                }
                {deleteAvionic.modal ?
                    <DeletePopUp
                        modal={deleteAvionic.modal}
                        title='Delete Avionic'
                        content={`Are you sure, you want to Delete?`}
                        toggleModalFn={() => setDeleteAvionic({ modal: false, data: null })}
                        deleteRecordFn={onDeleteAvionic}
                    /> : null
                }
                {isLoading ? <PageLoader /> : null}
            </Paper>
        </section>

    )
}
export default withRouter(Avionics);